/*eslint-disable max-len*/

export default {
  github: `M408.1,106.2c-19.6-33.6-46.2-60.2-79.8-79.8C294.7,6.8,258.1-3,218.3-3c-39.8,0-76.5,9.8-110.1,29.4
  C74.6,46,48,72.6,28.4,106.2C8.8,139.8-1,176.5-1,216.3C-1,264,12.9,307,40.8,345.2c27.9,38.2,63.9,64.6,108.1,79.2
  c5.1,1,8.9,0.3,11.4-2c2.5-2.3,3.7-5.1,3.7-8.6c0-0.6,0-5.7-0.1-15.4c-0.1-9.7-0.1-18.2-0.1-25.4l-6.6,1.1c-4.2,0.8-9.5,1.1-15.8,1
  c-6.4-0.1-13-0.8-19.8-2c-6.9-1.2-13.2-4.1-19.1-8.6c-5.9-4.5-10.1-10.3-12.6-17.6l-2.9-6.6c-1.9-4.4-4.9-9.2-9-14.6
  c-4.1-5.3-8.2-8.9-12.4-10.8l-2-1.4c-1.3-1-2.6-2.1-3.7-3.4c-1.1-1.3-2-2.7-2.6-4c-0.6-1.3-0.1-2.4,1.4-3.3s4.3-1.3,8.3-1.3
  l5.7,0.9c3.8,0.8,8.5,3,14.1,6.9c5.6,3.8,10.2,8.8,13.8,14.8c4.4,7.8,9.7,13.8,15.8,17.8c6.2,4.1,12.4,6.1,18.7,6.1
  s11.7-0.5,16.3-1.4c4.6-1,8.8-2.4,12.8-4.3c1.7-12.8,6.4-22.6,14-29.4c-10.8-1.1-20.6-2.9-29.3-5.1c-8.7-2.3-17.6-6-26.8-11.1
  c-9.2-5.1-16.9-11.5-23-19.1c-6.1-7.6-11.1-17.6-15-30c-3.9-12.4-5.9-26.6-5.9-42.8c0-23,7.5-42.6,22.6-58.8
  c-7-17.3-6.4-36.7,2-58.2c5.5-1.7,13.7-0.4,24.6,3.9c10.9,4.3,18.8,8,23.8,11c5,3,9.1,5.6,12.1,7.7c17.7-4.9,36-7.4,54.8-7.4
  s37.1,2.5,54.8,7.4l10.8-6.8c7.4-4.6,16.2-8.8,26.3-12.6c10.1-3.8,17.8-4.9,23.1-3.1c8.6,21.5,9.3,40.9,2.3,58.2
  c15,16.2,22.6,35.8,22.6,58.8c0,16.2-2,30.5-5.9,43c-3.9,12.5-8.9,22.5-15.1,30c-6.2,7.5-13.9,13.9-23.1,19
  c-9.2,5.1-18.2,8.9-26.8,11.1c-8.7,2.3-18.4,4-29.3,5.1c9.9,8.6,14.8,22.1,14.8,40.5v60.2c0,3.4,1.2,6.3,3.6,8.6
  c2.4,2.3,6.1,3,11.3,2c44.2-14.7,80.2-41.1,108.1-79.2c27.9-38.2,41.8-81.1,41.8-128.9C437.5,176.5,427.7,139.8,408.1,106.2z`,
  email: `M256,1L0,192.2v304h512v-304L256,1z M185.2,137.4c7.5-13.7,17.9-24.1,31-30.9c13.1-6.9,28-10.4,45-10.4
  c14.3,0,27,2.8,38.1,8.3c11.1,5.6,19.6,13.5,25.5,23.7c5.8,10.3,8.8,21.5,8.8,33.6c0,14.5-4.5,27.5-13.4,39.2
  c-11.2,14.8-25.5,22.1-43,22.1c-4.7,0-8.2-0.8-10.6-2.4c-2.3-1.7-4-4.1-4.8-7.3c-6.6,6.5-14.4,9.8-23.1,9.8
  c-9.4,0-17.2-3.3-23.5-9.7c-6.2-6.5-9.3-15.2-9.3-25.9c0-13.4,3.7-25.5,11.2-36.5c9.1-13.4,20.7-20.1,34.9-20.1
  c10.1,0,17.6,3.9,22.4,11.6l2.1-9.5h22.5l-12.9,61c-0.8,3.9-1.2,6.3-1.2,7.5c0,1.5,0.3,2.4,1,3.2c0.6,0.7,1.4,1.1,2.3,1.1
  c2.8,0,6.3-1.6,10.6-5c5.8-4.3,10.5-10.1,14-17.3c3.6-7.3,5.4-14.8,5.4-22.6c0-13.9-5-25.6-15.1-35.1
  c-10.1-9.3-24.3-14.1-42.3-14.1c-15.4,0-28.4,3.1-39.1,9.5c-10.7,6.2-18.8,15.1-24.2,26.5c-5.4,11.3-8.1,23.2-8.1,35.5
  c0,11.9,3.1,22.9,9.1,32.7c6.1,9.9,14.5,17,25.5,21.5c10.9,4.5,23.5,6.7,37.5,6.7c13.5,0,25.2-1.9,35-5.7c9.8-3.8,17.6-9.4,23.6-17
  h18.1c-5.7,11.5-14.3,20.5-26.1,27.1c-13.5,7.5-29.9,11.2-49.2,11.2c-18.8,0-34.9-3.1-48.6-9.4c-13.7-6.3-23.7-15.7-30.4-28
  c-6.6-12.3-9.9-25.8-9.9-40.3C173.9,166.1,177.6,151.2,185.2,137.4z M18.1,198.2l188.9,126.7L18.1,471.1V198.2z M32.4,480.8
  L256,307.7l223.6,173.1H32.4z M493.9,471.1L305.1,324.9l188.9-126.7L493.9,471.1L493.9,471.1z M265.3,151.3c3,3.4,4.5,8.3,4.5,14.8
  c0,5.7-1.1,11.9-3.3,18.5c-2.2,6.7-4.8,11.8-7.8,15.2c-2,2.4-4.2,4.2-6.4,5.3c-2.8,1.6-5.8,2.4-9.1,2.4c-4.3,0-7.9-1.7-10.9-5.1
  c-2.9-3.4-4.3-8.7-4.3-15.9c0-5.4,1-11.6,3.1-18.6c2.1-7,5.2-12.3,9.4-16.2c4.2-3.8,8.7-5.7,13.5-5.7
  C258.5,146.1,262.3,147.9,265.3,151.3z`,
  linkedin: `M256,0C114.6,0,0,114.6,0,256s114.6,256,256,256s256-114.6,256-256C512,114.6,397.4,0,256,0z M192,380h-64
  V156h64V380z M162,142.3c-16.6,0-30-13.4-30-30s13.4-30,30-30c16.6,0,30,13.5,30,30C192,128.8,178.6,142.3,162,142.3z M416,380
  h-64V241.5c0-16.2-4.6-27.6-24.6-27.6c-33.1,0-39.4,27.6-39.4,27.6V380h-64V156h64v21.4c9.2-7,32-21.4,64-21.4
  c20.7,0,64,12.4,64,87.4L416,380L416,380z`,
  resume: `M462.7,152c-4.4-10.7-9.8-19.1-16-25.3l-104-104c-6.2-6.2-14.7-11.6-25.3-16C306.7,2.2,296.9,0,288,0H74.7
  C65.8,0,58.2,3.1,52,9.3c-6.2,6.2-9.3,13.8-9.3,22.7v448c0,8.9,3.1,16.4,9.3,22.7c6.2,6.2,13.8,9.3,22.7,9.3h362.7
  c8.9,0,16.4-3.1,22.7-9.3c6.2-6.2,9.3-13.8,9.3-22.7V181.3C469.3,172.4,467.1,162.7,462.7,152z M298.7,45.3
  c6.4,2.2,11,4.7,13.7,7.3L416.7,157c2.7,2.7,5.1,7.2,7.3,13.7H298.7V45.3z M426.7,469.3H85.3V42.7H256v138.7
  c0,8.9,3.1,16.4,9.3,22.7c6.2,6.2,13.8,9.3,22.7,9.3h138.7V469.3z`,
  download: `M434.252,114.203l-21.409-21.416c-7.419-7.04-16.084-10.561-25.975-10.561c-10.095,0-18.657,3.521-25.7,10.561
  L222.41,231.549L83.653,92.791c-7.042-7.04-15.606-10.561-25.697-10.561c-9.896,0-18.559,3.521-25.979,10.561l-21.128,21.416
  C3.615,121.436,0,130.099,0,140.188c0,10.277,3.619,18.842,10.848,25.693l185.864,185.865c6.855,7.23,15.416,10.848,25.697,10.848
  c10.088,0,18.75-3.617,25.977-10.848l185.865-185.865c7.043-7.044,10.567-15.608,10.567-25.693
  C444.819,130.287,441.295,121.629,434.252,114.203z`
};
